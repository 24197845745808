import React from "react";
import { createButton, createSvgIcon } from "react-social-login-buttons";

const config = {
  activeStyle: { background: "rgb(79, 10, 109)" },
  icon: createSvgIcon(Icon),
  style: { background: "#720e9e" },
  text: "Login with Yahoo"
};

const YahooLoginButton = createButton(config);

export default YahooLoginButton;

function Icon({ width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      width={width}
      height={height}
      viewBox="0 0 512 512"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M222.218 144.056l2.949-27.73h-205.599v27.73l61.204 4.332 118.18 141.465v74.394l-67.113 4.382v24.76h204.165v-24.76l-67.082-2.939 2.928-77.291 112.261-100.618 59.832-5.806v-30.659l-172.094 1.485v27.689l64.154 1.485-83.108 71.424-86.026-105.011z"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M492.432 222.238l-54.2-2.458-2.478 129.054 25.457 2.437 31.222-129.034zM432.425 395.673h31.262v-26.337l-33.71-2.488 2.447 28.825z"
      />
    </svg>
  );
}
