import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  Avatar,
  Button,
  FormControl,
  Input,
  InputLabel,
  CircularProgress,
  Grid,
  Box
} from "@material-ui/core";

import {
  GoogleLoginButton,
  MicrosoftLoginButton
} from "react-social-login-buttons";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import firebase from "../../firebase";

import YahooLoginButton from "./YahooLoginButton";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  separator: {
    margin: theme.spacing(1),
    textAlign: "center",
    textTransform: "uppercase",
    color: "#acb6bf",
    overflow: "hidden"
  },
  separatorText: {
    position: "relative",
    display: "inline-block",
    "&:before": {
      right: "100%",
      marginRight: 12,
      content: "''",
      position: "absolute",
      top: "50%",
      height: 1,
      width: 1000,
      background: "#e1e6eb"
    },
    "&:after": {
      left: "100%",
      marginLeft: 12,
      content: "''",
      position: "absolute",
      top: "50%",
      height: 1,
      width: 1000,
      background: "#e1e6eb"
    }
  },
  socialBoxes: {
    marginTop: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

function SignIn(props) {
  const { classes } = props;

  const [email, setEmail] = useState("");

  const [authenticating, setAuthenticating] = useState(false);
  const [logginIn, setLogginIn] = useState(false);

  useEffect(() => {
    if (firebase.auth.isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      setAuthenticating(true);
      firebase.auth
        .signInWithEmailLink(email, window.location.href)
        .then(function(result) {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          props.history.replace("/");
        })
        .catch(function(error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, [props.history]);

  return authenticating === false ? (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Se connecter
        </Typography>
        <Grid
          spacing={1}
          container
          direction="column"
          className={classes.socialBoxes}
        >
          <Grid item xs={12}>
            <GoogleLoginButton onClick={loginWithGoogle}>
              Avec Google
            </GoogleLoginButton>
          </Grid>
          <Grid item xs={12}>
            <MicrosoftLoginButton onClick={loginWithMicrosoft}>
              Avec Microsoft ou Hotmail
            </MicrosoftLoginButton>
          </Grid>
          <Grid item xs={12}>
            <YahooLoginButton onClick={loginWithYahoo}>
              Avec Yahoo
            </YahooLoginButton>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.separator}>
              <Box className={classes.separatorText}>Ou par email</Box>
            </Box>
          </Grid>
        </Grid>
        {logginIn ? (
          <>
            <Typography variant="body2" color="textSecondary" component="p">
              Vous allez recevoir un email avec le lien pour vous connecter.
            </Typography>
            <CircularProgress />
          </>
        ) : (
          <form
            className={classes.form}
            onSubmit={e => e.preventDefault() && false}
          >
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Adresse email</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="off"
                autoFocus
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={loginWithEmail}
              className={classes.submit}
            >
              Se connecter
            </Button>
          </form>
        )}
      </Paper>
    </main>
  ) : (
    <CircularProgress />
  );

  async function loginWithGoogle() {
    const provider = new firebase.app.auth.GoogleAuthProvider();
    firebase.auth.languageCode = "fr";
    await firebase.auth.signInWithPopup(provider);
  }

  async function loginWithMicrosoft() {
    const provider = new firebase.app.auth.OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      prompt: "select_account"
    });
    firebase.auth.languageCode = "fr";
    await firebase.auth.signInWithPopup(provider);
  }
  async function loginWithYahoo() {
    const provider = new firebase.app.auth.OAuthProvider("yahoo.com");
    provider.setCustomParameters({
      // Prompt user to re-authenticate to Yahoo.
      prompt: "login",
      // Localize to French.
      language: "fr"
    });
    firebase.auth.languageCode = "fr";
    await firebase.auth.signInWithPopup(provider);
  }

  async function loginWithEmail() {
    try {
      await firebase.login(email);
      window.localStorage.setItem("emailForSignIn", email);
      setLogginIn(true);
    } catch (error) {
      alert(error.message);
    }
  }
}

export default withRouter(withStyles(styles)(SignIn));
