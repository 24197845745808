import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import MaterialTable from "material-table";

import firebase from "../../firebase";

const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
});

const queryData = (query) => {
  return new Promise((resolve, reject) => {
    console.log(query);
    let ref = firebase.db
      .collection("registrations")
      .where("pending", "==", true);
    if (query.orderBy) {
      ref = ref.orderBy(query.orderBy.field, query.orderDirection);
    }
    if (query.search) {
      ref = ref
        .where("email", ">=", query.search)
        .where("email", "<=", query.search + "\uf8ff");
    }
    ref
      .limit(1000)
      .get()
      .then((data) => {
        const values = data.docs.map((snap) => {
          const data = snap.data();
          data.id = snap.id;
          return data;
        });

        resolve({
          data: values,
          page: 0,
          totalCount: data.size,
        });
      });
  });
};

const validateRegistration = (registration) => {
  console.log(registration);
  firebase.approveUser({ registrationRef: registration.id });
};

function Users(props) {
  const { classes } = props;

  return (
    <MaterialTable
      columns={[
        { title: "Email", field: "email", searchable: true },
        { title: "Name", field: "name", searchable: true },
        {
          title: "Date",
          field: "registeredAt",
          render: (rowData) => {
            if (!!!rowData.registeredAt) {
              return;
            }
            const date = new Date(rowData.registeredAt.seconds * 1000);
            return (
              <span>
                {date.toLocaleDateString()} {date.toLocaleTimeString()}
              </span>
            );
          },
        },
      ]}
      data={queryData}
      options={{ paginationType: "stepped", pagination: false }}
      actions={[
        {
          icon: "save",
          tooltip: "Validate",
          onClick: (event, rowData) => validateRegistration(rowData),
        },
      ]}
    ></MaterialTable>
  );
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Users);
