import app from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import "firebase/firebase-functions";
import config from "./firebaseConfig";

var actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: "https://theatre.ecoleouverte.be/login",
  // This must be true.
  handleCodeInApp: true,
};

class Firebase {
  constructor() {
    this.app = app;
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.app().functions("europe-west1");

    if (window.location.hostname === "localhost") {
      console.log("localhost detected!");
      /*
      this.db.settings({
        host: "localhost:8080",
        ssl: false
      });
      */
      this.functions.useFunctionsEmulator("http://localhost:5001");
      actionCodeSettings.url = "http://localhost:3000/";
    }
    this.getDownloadLink = this.functions.httpsCallable("getDownloadLink");
    this.initiatePayment = this.functions.httpsCallable("initiatePayment");
    this.createOrder = this.functions.httpsCallable("createOrder");
    this.approveUser = this.functions.httpsCallable("approveUser");
  }

  login(email) {
    return this.auth.sendSignInLinkToEmail(email, actionCodeSettings);
  }

  logout() {
    return this.auth.signOut();
  }

  async register(name, email, password) {
    await this.auth.createUserWithEmailAndPassword(email, password);
    return this.auth.currentUser.updateProfile({
      displayName: name,
    });
  }

  addQuote(quote) {
    if (!this.auth.currentUser) {
      return alert("Not authorized");
    }

    return this.db
      .doc(`users_codedamn_video/${this.auth.currentUser.uid}`)
      .set({
        quote,
      });
  }

  isInitialized() {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
  }

  getCurrentUsername() {
    return this.auth.currentUser && this.auth.currentUser.email;
  }

  async getCurrentUserQuote() {
    const quote = await this.db
      .doc(`users_codedamn_video/${this.auth.currentUser.uid}`)
      .get();
    return quote.get("quote");
  }
}

export default new Firebase();
