import React from "react";
import { withStyles, Hidden } from "@material-ui/core";
import Navigator from "./Navigator";
import { Switch, Route } from "react-router-dom";

import PeopleIcon from "@material-ui/icons/People";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import VideoIcon from "@material-ui/icons/VideoLibrary";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Users from "./Users";
import Registrations from "./Registrations";
import Orders from "./Orders";

export const categories = [
  {
    id: "Gestion",
    children: [
      { id: "Utilisateurs", icon: <PeopleIcon />, to: "/admin" },
      { id: "Videos", icon: <VideoIcon />, to: "/admin/test" },
      { id: "Commandes", icon: <ShoppingCartIcon />, to: "/admin/orders" },
      {
        id: "Registrations",
        icon: <GroupAddIcon />,
        to: "/admin/registrations",
      },
    ],
  },
];

const styles = (theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
  footer: {
    padding: theme.spacing(2),
    background: "#eaeff1",
  },
});

const Test = () => {
  return <span>Test</span>;
};

function Admin(props) {
  const { classes } = props;
  return (
    <div className={classes.main}>
      <Route exact path={props.match.path} component={Users} />
      <Route path={`${props.match.path}/test`} component={Test} />
      <Route path={`${props.match.path}/orders`} component={Orders} />
      <Route
        path={`${props.match.path}/registrations`}
        component={Registrations}
      />
    </div>
  );
}
export default withStyles(styles)(Admin);
