const firebaseConfig = {
  apiKey: "AIzaSyDjHiakLKdwyc5TNNzDGnCpyD-hKXKlcvo",
  authDomain: "theatre.ecoleouverte.be",
  //databaseURL: "https://theatre-eo.firebaseio.com",
  //databaseURL: "http://localhost:8080",
  projectId: "theatre-eo",
  storageBucket: "theatre-eo.appspot.com",
  messagingSenderId: "980872781692",
  appId: "1:980872781692:web:636b74745c6a3453facef9",
  measurementId: "G-2FWZS7KL0B",
};

export default firebaseConfig;
