import React, { useState, useEffect } from "react";
import HomePage from "../HomePage";
import Login from "../Login";

import "./index.css";

import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import {
  CssBaseline,
  CircularProgress,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Hidden,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import firebase from "../../firebase";
import Admin, { categories } from "../Admin";
import Navigator from "../Admin/Navigator";

const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  main: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

let theme = createMuiTheme({
  palette: {
    primary: { main: "#343943" },
    secondary: {
      main: "#96ccfa",
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};
export default function App() {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  const [user, setUser] = useState();
  const [admin, setAdmin] = useState(false);
  const [approved, setApproved] = useState();

  async function logout() {
    await firebase.logout();
  }

  const logUser = (user, val) => {
    if (user) {
      /* Get token */
      user
        .getIdTokenResult(true)
        .then((idTokenResult) => {
          console.log("Check admin");
          console.log(idTokenResult.claims);
          if (true /* !!idTokenResult.claims.approved */) {
            setApproved(true);
          } else {
            setApproved(false);
          }
          if (!!idTokenResult.claims.admin) {
            setAdmin(true);
            setApproved(true);
          } else {
            setAdmin(false);
          }
          setUser(user);
          setFirebaseInitialized(val);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setUser(null);
      setFirebaseInitialized(val);
    }
  };

  useEffect(() => {
    var unsubscribe;
    if (user) {
      /* Listen to registration changes */
      unsubscribe = firebase.db
        .collection("registrations")
        .where("email", "==", user.email)
        .onSnapshot(
          (reg) => {
            /* Refresh user if registration changed */
            logUser(user);
          },
          (error) => {
            console.log("error on registrations ", error);
          }
        );
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [user]);

  useEffect(() => {
    firebase.isInitialized().then((val) => {
      firebase.auth.onAuthStateChanged((user) => {
        logUser(user, val);
      });
    });
  }, []);

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return firebaseInitialized !== false ? (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className={classes.root}>
          <Route path="/admin">
            <nav className={classes.drawer}>
              <Hidden smUp implementation="js">
                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  categories={categories}
                />
              </Hidden>
              <Hidden xsDown implementation="css">
                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  categories={categories}
                />
              </Hidden>
            </nav>
          </Route>
          <div className={classes.main}>
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  component={Link}
                  to="/"
                >
                  <HomeIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}></Typography>
                {user && (
                  <>
                    {user.email}
                    {admin && (
                      <Button color="inherit" component={Link} to="/admin">
                        Admin
                      </Button>
                    )}
                    <Button color="inherit" onClick={logout}>
                      Logout
                    </Button>
                  </>
                )}
              </Toolbar>
            </AppBar>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <HomePage {...props} user={user} approved={approved} />
                )}
              />
              <Route exact path="/login" component={Login} />
              <Route path="/admin" component={Admin} />
            </Switch>
          </div>
        </div>
      </Router>
    </MuiThemeProvider>
  ) : (
    <div id="loader">
      <CircularProgress />
    </div>
  );
}
