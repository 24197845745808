import React, { useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";

import firebase from "../../firebase";

const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
});

const queryData = (query) => {
  return new Promise((resolve, reject) => {
    console.log(query);
    let ref = firebase.db.collection("registrations");

    if (query.search) {
      ref = ref
        .where("email", ">=", query.search)
        .where("email", "<=", query.search + "\uf8ff");
    } else {
      if (query.orderBy) {
        ref = ref.orderBy(query.orderBy.field, query.orderDirection);
      }
    }
    if (query.filters) {
      query.filters.forEach((filter) => {
        if (filter.column.field === "pending") {
          ref = ref.where("pending", "==", filter.value === "checked");
        }
      });
    }
    ref
      .limit(1000)
      .get()
      .then((data) => {
        const values = data.docs.map((snap) => {
          const data = snap.data();
          data.id = snap.id;
          return data;
        });
        console.log(values);
        resolve({
          data: values,
          page: 0,
          totalCount: data.size,
        });
      })
      .catch((error) => {
        console.log("Error", error);
        resolve({
          data: [],
          page: 0,
          totalCount: 0,
        });
      });
  });
};

function Registrations(props) {
  const tableRef = useRef(null);
  const validateRegistration = async (registration, validate) => {
    await firebase.approveUser({ registrationRef: registration.id, validate });
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };
  return (
    <MaterialTable
      tableRef={tableRef}
      columns={[
        { title: "Email", field: "email", searchable: true, filtering: false },
        { title: "Name", field: "name", filtering: false },
        {
          title: "Validated",
          field: "validated",
          type: "boolean",
          filtering: false,
        },
        {
          title: "Pending",
          field: "pending",
          type: "boolean",
          defaultFilter: "checked",
        },
        {
          title: "Date",
          field: "registeredAt",
          defaultSort: "desc",
          filtering: false,
          render: (rowData) => {
            if (!!!rowData.registeredAt) {
              return;
            }
            const date = new Date(rowData.registeredAt.seconds * 1000);
            return (
              <span>
                {date.toLocaleDateString()} {date.toLocaleTimeString()}
              </span>
            );
          },
        },
      ]}
      data={queryData}
      options={{
        paginationType: "stepped",
        paging: false,
        pageSize: 1000,
        pageSizeOptions: [],
        filtering: true,
      }}
      actions={[
        {
          icon: "check",
          tooltip: "Validate",
          onClick: (event, rowData) => validateRegistration(rowData, true),
        },
        {
          icon: "clear",
          tooltip: "refuse",
          onClick: (event, rowData) => validateRegistration(rowData, false),
        },
        {
          icon: "refresh",
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () => tableRef.current && tableRef.current.onQueryChange(),
        },
      ]}
    ></MaterialTable>
  );
}

Registrations.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Registrations);
