import React from "react";
import Dashboard from "../Dashboard";
import Login from "../Login";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  header: {
    color: "white",
    minHeight: 200,
    background:
      "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(https://theatre-eo.s3-eu-west-1.amazonaws.com/2019/THEATRE-EO-2019-M1.005.jpg) ",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    display: "flex",
    alignItems: "center",
    width: "100%",
    textAlign: "center",
    marginBottom: 16,
  },
  headline: {
    marginLeft: "auto",
    marginRight: "auto",

    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem",
    },
  },
});

function HomePage(props) {
  const { classes, user, approved } = props;
  return (
    <>
      <div className={classes.header}>
        <h1 className={classes.headline}>
          Les vidéos du Théâtre de l'Ecole Ouverte
        </h1>
      </div>
      {user ? <Dashboard approved={approved} /> : <Login />}
    </>
  );
}
export default withStyles(styles)(HomePage);
