import React, { useEffect, useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import firebase from "../../firebase";
import { withRouter } from "react-router-dom";
import VideoCard from "./VideoCard";

const styles = (theme) => ({
  header: {
    [theme.breakpoints.up("sm")]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  video: {
    [theme.breakpoints.up("sm")]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  media: {
    height: 240,
  },
});

function Dashboard(props) {
  const { classes, approved } = props;

  const [videoList, setVideoList] = useState();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    console.log("Get orders");
    const unsubscribe = firebase.db
      .collection("orders")
      .where("email", "==", firebase.getCurrentUsername())
      .where("paid", "==", true)
      .orderBy("date", "desc")
      .onSnapshot(
        (res) => {
          Promise.all(
            res.docs.map((doc) => {
              let order = doc.data();
              order.id = doc.id;
              if (order.video) {
                return order.video.get().then((res) => {
                  order.videoData = res.data();
                  order.videoData.id = res.id;
                  return order;
                });
              } else {
                return order;
              }
            })
          )
            .then((orders) => setOrders(orders))
            .catch((error) => setOrders([]));
        },
        (error) => {
          setOrders([]);
        }
      );
    return () => unsubscribe();
  }, [approved]);

  useEffect(() => {
    console.log("Get videos");
    firebase.db
      .collection("videos")
      .orderBy("position")
      .get()
      .then((videoList) => {
        setVideoList(videoList.docs);
      })
      .catch((error) => setVideoList([]));
  }, [approved]);

  return (
    <main className={classes.main}>
      {approved ? (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={4}
        >
          {videoList ? (
            videoList.map((video) => (
              <Grid key={video.id} item xs={12} sm>
                <VideoCard
                  classes={classes}
                  video={{ id: video.id, ...video.data() }}
                  order={orders
                    .filter((order) => order.videoData.id === video.id)
                    .pop()}
                />
              </Grid>
            ))
          ) : (
            <Grid item>
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      ) : (
        <span>Votre compte doit être validé par l'école</span>
      )}
    </main>
  );
}

export default withRouter(withStyles(styles)(Dashboard));
