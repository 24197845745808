import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import {
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  CircularProgress,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  makeStyles,
} from "@material-ui/core";

import PlayIcon from "@material-ui/icons/PlayArrow";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "video-react/dist/video-react.css";

import firebase from "../../firebase";
import plyr from "plyr";
import "plyr/dist/plyr.css";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe("pk_test_JhwbWe9So2HocX2w4BMv9MaJ00iIvQ0brI");

const useStyle = makeStyles((theme) => ({
  paymentTitle: {
    marginBottom: 12,
  },
  paymentMethod: {
    borderColor: "lightgrey",
    borderWidth: 1,
    borderStyle: "solid",
    padding: "6px 12px",
    margin: 4,
    borderRadius: 4,
  },
  leftPane: {
    backgroundColor: "lightgrey",
  },
}));

function RequestVideoDialog(props) {
  const { open, handleClose, title, video, amount } = props;
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("bancontact");
  const classes = useStyle();
  const bancontact = () => {
    console.log("BANCONTACT");
    console.log("video: ", video);
    setLoading(true);
    firebase
      .createOrder({ video: video })
      .then((result) => (window.location = result.data.source.redirect.url))
      .catch(() => setLoading(false));
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Demander l'accès à la vidéo: " + title}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {!!!loading ? (
              <>
                <DialogContentText id="alert-dialog-description">
                  Vous avez la possibilité de payer{" "}
                  {(amount / 100.0).toFixed(2)}€ pour obtenir l'accès à cette
                  vidéo
                </DialogContentText>
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    className={classes.paymentTitle}
                  >
                    Choisissez votre méthode de paiement:
                  </FormLabel>
                  <RadioGroup
                    value={paymentMethod}
                    onChange={(event) => setPaymentMethod(event.target.value)}
                  >
                    <Grid container>
                      <Grid item xs={12} className={classes.paymentMethod}>
                        <FormControlLabel
                          value="bancontact"
                          label="Bancontact"
                          control={<Radio />}
                        ></FormControlLabel>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </>
            ) : (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="stretch"
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {!!!loading && (
          <>
            <Button onClick={handleClose} color="primary" autoFocus>
              Annuler
            </Button>
            <Button onClick={bancontact} color="primary">
              Payer
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

function Plyr(props) {
  const videoPlayer = useRef(null);

  useLayoutEffect(() => {
    const player = new plyr(videoPlayer.current, {
      autoplay: true,
      controls: [
        "play-large",
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "captions",
        "settings",
        "airplay",
        "fullscreen",
      ],
    });
    player.source = props.sources;
    return () => {
      player.destroy();
      console.log("destroy player");
    };
  }, []);

  return <video className="plyr" ref={videoPlayer}></video>;
}

function VideoCard(props) {
  const { classes, video, order = {} } = props;
  const [playing, setPlaying] = useState();
  const [request, setRequest] = useState(false);
  const download = () => {
    if (order.id) {
      firebase.getDownloadLink({ order: order.id }).then((data) => {
        window.location = data.data.url;
      });
    } else {
      firebase.getDownloadLink({ video: video.id }).then((data) => {
        window.location = data.data.url;
      });
    }
  };
  const play = () => {
    if (order.id) {
      firebase.getDownloadLink({ order: order.id }).then((data) => {
        setPlaying(data.data.url);
      });
    } else {
      firebase.getDownloadLink({ video: video.id }).then((data) => {
        setPlaying(data.data.url);
      });
    }
  };

  const handleRequest = () => {
    setRequest(true);
  };
  const handleClose = () => {
    setRequest(false);
  };
  return (
    <Card className={classes.video}>
      {playing ? (
        <CardMedia className={classes.media}>
          {/* <Player autoPlay playing={true} src={playing} /> */}
          <Plyr
            sources={{ type: "video", sources: [{ src: playing }] }}
            autoplay
          />
        </CardMedia>
      ) : (
        <CardMedia className={classes.media} image={video.thumbnail} />
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {video.title}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
        ></Typography>
      </CardContent>
      {video.protected && !order.paid ? (
        <CardActions>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={handleRequest}
          >
            Demander l'accès
          </Button>
          <Elements stripe={stripePromise}>
            <RequestVideoDialog
              title={video.title}
              open={request}
              video={video.id}
              amount={video.amount}
              handleClose={handleClose}
            />
          </Elements>
        </CardActions>
      ) : (
        <CardActions>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            startIcon={<PlayIcon />}
            onClick={play}
          >
            Play
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            startIcon={<SaveAltIcon />}
            onClick={download}
          >
            Download
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

export default VideoCard;
